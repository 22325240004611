export const formNames = {
  DATE: 'date',
  WO_NUMBER: 'woNumber',
  AATYPE: 'aaType',
  WAGE_TYPE: 'wageType',
  HOURS: 'hours',
  UNITS: 'units',
  MILES: 'miles',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  VAL_BASIS: 'valBasis',
  OC_CODE: 'ocCode',
  SHORT_TEXT: 'shortText',
  PREVIOUS_DAY: 'previousDay'
};
