import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import IconReset from '../../../assets/icon-reset.svg';

import StyledButton from '../../Common/Button';

import { ROUTES } from '../../../constants/routes';
import { TIME_ENTRY_IDS } from '../../../constants/elementIds';

import { currentWeekRange } from '../../../utils/dates';

import './styles.scss';

const InfoContent = ({ userId, dataEntryPeriod, handleNavigation, setNextWeek, setPreviousWeek }) => {
  const location = useLocation();

  const handleBack = () => {
    handleNavigation(ROUTES.ROOT);
  };

  return (
    <div className='info-container'>
      <div id='user-id-container' className='top-row'>
        <div className='info-content'>
          <span className='span-content'>{`User: ${userId?.toUpperCase()}`}</span>
        </div>
      </div>
      <div id='week-container'>
        <div id='info-week' className='info-content'>
          <IconButton id={TIME_ENTRY_IDS.previousWeekButton} onClick={setPreviousWeek}>
            <ArrowForwardIosIcon id='previous-week-icon' />
          </IconButton>
          <span className='span-content'>{currentWeekRange(dataEntryPeriod)}</span>
          <IconButton id={TIME_ENTRY_IDS.nextWeekButton} onClick={setNextWeek}>
            <ArrowForwardIosIcon id='next-week-icon' />
          </IconButton>
        </div>
      </div>
      <div id='info-actions' className='top-row'>
        {location.pathname === ROUTES.SUMMARY && (
          <StyledButton
            id={TIME_ENTRY_IDS.summaryBackButton}
            icon={<img src={IconReset} alt='back' />}
            className='back-button'
            onClick={handleBack}
          >
            Go Back
          </StyledButton>
        )}
      </div>
    </div>
  );
};

export default InfoContent;
