import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as applicationActions from '../../ducks/application';
import * as entriesActions from '../../ducks/entries';
import * as timecardActions from '../../ducks/timecard';

import NavigationView from './View';

const mapStateToProps = ({ application, entries, timecard }) => ({
  dataInFlight: application.dataInFlight,
  signedIn: application.signedIn,
  userId: application.userId,
  credentials: application.userCredentials,
  modalConfirmHandler: application.modalConfirmHandler,
  online: application.online,
  optionalModalHandler: application.modalConfirmHandlerOptional,
  unsubmittedCount: entries.unsubmittedCount,
  warningCount: entries.warningCount,
  modalMessage: application.modalMessage,
  totalHours: entries.totalHours,
  dataEntryPeriod: timecard.dataEntryPeriod
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...applicationActions,
      ...entriesActions,
      ...timecardActions
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationView));
