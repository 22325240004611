import { any, equals } from 'ramda';
import { fromStorage, toStorage, generateDEPString } from '../utils/storage';

import '../types';

const ENTRIES_STAGED_FOR_DELETION = 'entriesStagedForDeletion';
const ENTRIES_STAGED_FOR_EDIT = 'entriesStagedForEdit';
const UNSUBMITTED_ENTRIES = 'unsubmittedEntries';

/**
 * @function getStagedForDeletionEntries
 * @returns {Array.<Entry>}
 */
export const getStagedForDeletionEntries = (dataEntryPeriod, userId) =>
  JSON.parse(fromStorage(`${userId}-${ENTRIES_STAGED_FOR_DELETION}-${generateDEPString(dataEntryPeriod)}`, '[]'));

/**
 * @function getStagedForEditEntries
 * @returns {Array.<Entry>}
 */
export const getStagedForEditEntries = (dataEntryPeriod, userId) =>
  JSON.parse(fromStorage(`${userId}-${ENTRIES_STAGED_FOR_EDIT}-${generateDEPString(dataEntryPeriod)}`, '[]'));

/**
 * @function getUnsubmittedEntries
 * @returns {Array.<Entry>}
 */
export const getUnsubmittedEntries = (dataEntryPeriod, userId) =>
  JSON.parse(fromStorage(`${userId}-${UNSUBMITTED_ENTRIES}-${generateDEPString(dataEntryPeriod)}`, '[]'));

/**
 * @function getAllEntries
 * @returns {Array.<Entry>}
 */
export const getAllEntries = (dataEntryPeriod, userId, includeDeleted = false) => [
  ...getStagedForEditEntries(dataEntryPeriod, userId),
  ...getUnsubmittedEntries(dataEntryPeriod, userId),
  ...(includeDeleted && getStagedForDeletionEntries(dataEntryPeriod, userId))
];

/**
 * @function getAllStagedEntries
 * @returns {{
 *   entriesStagedForDeletion: Array.<Entry>,
 *   entriesStagedForEdit: Array.<Entry>,
 *   unsubmittedEntries: Array.<Entry>
 * }}
 */
export const getAllStagedEntries = (dataEntryPeriod, userId) => [
  ...getStagedForDeletionEntries(dataEntryPeriod, userId),
  ...getStagedForEditEntries(dataEntryPeriod, userId),
  ...getUnsubmittedEntries(dataEntryPeriod, userId)
];

/**
 * @function setStagedForDeletionEntries
 * @param {Array.<Entry>} entries
 * @returns {void}
 */
export const setStagedForDeletionEntries = (entries, dataEntryPeriod, userId) => {
  toStorage(`${userId}-${ENTRIES_STAGED_FOR_DELETION}-${generateDEPString(dataEntryPeriod)}`, JSON.stringify(entries));
};

/**
 * @function setStagedForEditEntries
 * @param {Array.<Entry>} entries
 * @returns {void}
 */
export const setStagedForEditEntries = (entries, dataEntryPeriod, userId) => {
  toStorage(`${userId}-${ENTRIES_STAGED_FOR_EDIT}-${generateDEPString(dataEntryPeriod)}`, JSON.stringify(entries));
};

/**
 * @function setUnsubmittedEntries
 * @param {Array.<Entry>}
 * @returns {void}
 */
export const setUnsubmittedEntries = (entries, dataEntryPeriod, userId) => {
  toStorage(`${userId}-${UNSUBMITTED_ENTRIES}-${generateDEPString(dataEntryPeriod)}`, JSON.stringify(entries));
};

/**
 * @function clearUnsubmittedEntries
 */
export const clearUnsubmittedEntries = (dataEntryPeriod, userId) => {
  toStorage(`${userId}-${UNSUBMITTED_ENTRIES}-${generateDEPString(dataEntryPeriod)}`, undefined);
};

/**
 * @function clearStagedForDeletionEntries
 */
export const clearStagedForDeletionEntries = (dataEntryPeriod, userId) => {
  toStorage(`${userId}-${ENTRIES_STAGED_FOR_DELETION}-${generateDEPString(dataEntryPeriod)}`, undefined);
};

/**
 * @function clearStagedForEditEntries
 */
export const clearStagedForEditEntries = (dataEntryPeriod, userId) => {
  toStorage(`${userId}-${ENTRIES_STAGED_FOR_EDIT}-${generateDEPString(dataEntryPeriod)}`, undefined);
};

/**
 * @function getUnsubmittedEntryById
 * @param {string} id
 */
export const getUnsubmittedEntryById = (id, dataEntryPeriod, userId) => {
  const existingEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  return existingEntries.find((entry) => entry.EntryId === id);
};

/**
 * @function addUnsubmittedEntry
 * @param {Entry} entry
 */
export const addUnsubmittedEntry = (entry, dataEntryPeriod, userId) => {
  const existingEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  setUnsubmittedEntries([...existingEntries, entry], dataEntryPeriod, userId);
};

/**
 * @function replaceUnsubmittedEntry
 * @param {Entry} replacementEntry
 */
export const replaceUnsubmittedEntry = (replacementEntry, dataEntryPeriod, userId) => {
  const existingEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  const updatedEntries = existingEntries.map((entry) => {
    if (entry.EntryId === replacementEntry.EntryId) {
      return replacementEntry;
    }
    return entry;
  });
  setUnsubmittedEntries(updatedEntries, dataEntryPeriod, userId);
};

/**
 * @function replaceStagedForEditEntry
 * @param {Entry} replacementEntry
 */
export const replaceStagedForEditEntry = (replacementEntry, dataEntryPeriod, userId) => {
  const existingEntries = getStagedForEditEntries(dataEntryPeriod, userId);
  const updatedEntries = existingEntries.map((entry) => {
    if (entry.EntryId === replacementEntry.EntryId) {
      return replacementEntry;
    }
    return entry;
  });
  setStagedForEditEntries(updatedEntries, dataEntryPeriod, userId);
};

/**
 * @function replaceStagedForDeletionEntry
 * @param {Entry} replacementEntry
 */
export const replaceStagedForDeletionEntry = (replacementEntry, dataEntryPeriod, userId) => {
  const existingEntries = getStagedForDeletionEntries(dataEntryPeriod, userId);
  const updatedEntries = existingEntries.map((entry) => {
    if (entry.EntryId === replacementEntry.EntryId) {
      return replacementEntry;
    }
    return entry;
  });
  setStagedForDeletionEntries(updatedEntries, dataEntryPeriod, userId);
};

/**
 * @function replaceStagedEntries
 * @param {Array.<Entry>} entries
 */
export const replaceStagedEntries = (entries, dataEntryPeriod, userId) => {
  const stagedForDeletionEntries = getStagedForDeletionEntries(dataEntryPeriod, userId);
  const stagedForEditEntries = getStagedForEditEntries(dataEntryPeriod, userId);
  const unsubmittedEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  entries.forEach((entry) => {
    const stagedForDeletion = stagedForDeletionEntries.find((stagedEntry) =>
      equals(stagedEntry.EntryId, entry.EntryId)
    );
    const stagedForEdit = stagedForEditEntries.find((stagedEntry) => equals(stagedEntry.EntryId, entry.EntryId));
    const unsubmitted = unsubmittedEntries.find((stagedEntry) => equals(stagedEntry.EntryId, entry.EntryId));
    if (stagedForDeletion) {
      replaceStagedForDeletionEntry(entry, dataEntryPeriod, userId);
    } else if (stagedForEdit) {
      replaceStagedForEditEntry(entry, dataEntryPeriod, userId);
    } else if (unsubmitted) {
      replaceUnsubmittedEntry(entry, dataEntryPeriod, userId);
    }
  });
};

/**
 * @function addStagedForDeletionEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const addStagedForDeletionEntry = (entry, dataEntryPeriod, userId) => {
  const existingStagedEntries = getStagedForDeletionEntries(dataEntryPeriod, userId);
  setStagedForDeletionEntries([...existingStagedEntries, entry], dataEntryPeriod, userId);
};

/**
 * @function addStagedForEditEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const addStagedForEditEntry = (entry, dataEntryPeriod, userId) => {
  const existingStagedEntries = getStagedForEditEntries(dataEntryPeriod, userId);
  setStagedForEditEntries([...existingStagedEntries, entry], dataEntryPeriod, userId);
};

/**
 * @function removeStagedForDeletionEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const removeStagedForDeletionEntry = (id, dataEntryPeriod, userId) => {
  const existingEntries = getStagedForDeletionEntries(dataEntryPeriod, userId);
  const entries = existingEntries.filter((entry) => entry.EntryId !== id);
  setStagedForDeletionEntries(entries, dataEntryPeriod, userId);
};

/**
 * @function removeUnsubmittedEntry
 * @param {string} id
 */
export const removeUnsubmittedEntry = (id, dataEntryPeriod, userId) => {
  const existingEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  const entries = existingEntries.filter((entry) => entry.EntryId !== id);
  setUnsubmittedEntries(entries, dataEntryPeriod, userId);
};

/**
 * @function removeStagedForEditEntry
 * @param {string} id
 */
export const removeStagedForEditEntry = (id, dataEntryPeriod, userId) => {
  const existingEntries = getStagedForEditEntries(dataEntryPeriod, userId);
  const entries = existingEntries.filter((entry) => entry.EntryId !== id);
  setStagedForEditEntries(entries, dataEntryPeriod, userId);
};

export const clearDuplicateEntries = (dataEntryPeriod, userId) => {
  const editedEntries = getStagedForEditEntries(dataEntryPeriod, userId);
  const deletedEntries = getStagedForDeletionEntries(dataEntryPeriod, userId);
  editedEntries.forEach(editedEntry => {
    const entryExists = any(deletedEntry => deletedEntry.EntryId === editedEntry.EntryId)(deletedEntries);
    if (entryExists) {
      removeStagedForEditEntry(editedEntry.EntryId, dataEntryPeriod, userId);
      removeStagedForDeletionEntry(editedEntry.EntryId, dataEntryPeriod, userId);
    }
  });
};
