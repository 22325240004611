import { isEmpty } from 'ramda';
import { getStagedForDeletionEntries, getStagedForEditEntries, getUnsubmittedEntries } from '../LocalStorage';
import { ENTRY_STATUS } from '../constants';

import '../types';

/**
 * @function getStagedEntries
 * @description Get all staged entries from local storage and modify updates and deletions
 * @returns {Array<Entry>}
 */
export const getStagedEntries = (dataEntryPeriod, userId) => {
  // Get all local staged entries
  const unsubmittedEntries = getUnsubmittedEntries(dataEntryPeriod, userId);
  const entriesStagedForDeletion = getStagedForDeletionEntries(dataEntryPeriod, userId);
  const entriedStagedForEdit = getStagedForEditEntries(dataEntryPeriod, userId);

  // Remove manually created EntryIds
  unsubmittedEntries.map(entry => {
    const modifiedEntry = entry;
    modifiedEntry.EntryId = '';
    return modifiedEntry;
  });

  // Merge all staged entries
  const stagedEntries = [...entriesStagedForDeletion, ...entriedStagedForEdit, ...unsubmittedEntries];

  if (isEmpty(stagedEntries)) {
    return [];
  }

  // Modify attributes depending on action
  const stagedForDeletion = stagedEntries.map((entry) => {
    const modifiedEntry = entry;
    if (modifiedEntry.Status === ENTRY_STATUS.DELETED) {
      modifiedEntry.Action = 'D';
      modifiedEntry.Status = 'Saved';
      modifiedEntry.Editable = true;
      modifiedEntry.FromSAP = true;
    } else if (modifiedEntry.Status === ENTRY_STATUS.EDITED) {
      modifiedEntry.Action = 'U';
      modifiedEntry.Status = 'Saved';
      modifiedEntry.Editable = true;
      modifiedEntry.FromSAP = true;
    }
    return modifiedEntry;
  });

  return stagedForDeletion;
};
