import '../types';

/**
 * @function unsubmittedEntriesToSubmissionData
 * @param {string} userId
 * @param {DataEntryPeriod} dataEntryPeriod
 * @param {string} CorrelationId
 * @param {Array.<Entry>} stagedEntries
 * @param {string} IgnoredWarnings
 * @returns {TimeCardData}
 */
export const unsubmittedEntriesToSubmissionData = (
  userId,
  dataEntryPeriod,
  CorrelationId,
  stagedEntries,
  IgnoredWarnings
) => ({
  TimeCard: {
    WageTypes: [],
    ActivityTypes: [],
    WorkScheduleHours: [],
    OverTimeTypes: [],
    EmployeeId: userId,
    DataEntryPeriod: {
      StartDate: dataEntryPeriod.StartDate,
      EndDate: dataEntryPeriod.EndDate
    },
    LineItems: stagedEntries,
    ApplicationId: 'TEA',
    CorrelationId,
    IgnoredWarnings
  }
});
