import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import './styles.scss';

const StyledButton = ({ onClick, icon, fluid, disabled, children, className, secondary, ...props }) => (
  <Button
    className={`button ${fluid && 'fluid'} ${secondary && 'secondary'} ${className && className}`}
    onClick={onClick}
    disabled={disabled}
    {...(icon && { startIcon: icon })}
    {...props}
  >
    {children}
  </Button>
);

StyledButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool
};

export default StyledButton;
