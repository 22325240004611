/* eslint no-underscore-dangle:0 */
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import history from './history';
import createRootReducer from '../ducks';

const middleware = [
  routerMiddleware(history),
  thunk
];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancer(applyMiddleware(...middleware))
);

export default store;
