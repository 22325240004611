import * as React from 'react';

import './styles.scss';

const NotFoundView = () => (
  <div id='not-found-view'>
    <span>Page Not Found</span>
  </div>
);

export default NotFoundView;
