import { curry } from 'ramda';

import { tryParse } from './string';

/**
 * Retrieves a value from storage for the given key.
 */
export const fromStorage = (key, defaultValue) => tryParse(defaultValue, window.localStorage.getItem(key));

/**
 * Sets a value in storage for the given key.
 */
export const toStorage = curry((key, value) => window.localStorage.setItem(key, JSON.stringify(value)));

/**
 * Returns Local Storage data entry period key
 * @function generateDEPString
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {string}
 */
export const generateDEPString = (dataEntryPeriod) => `${dataEntryPeriod.StartDate}-${dataEntryPeriod.EndDate}`;

/**
 * Clears the entire local storage
 * @function clearStorage
 * @returns {void}
 */
export const clearStorage = () => window.localStorage.clear();

/**
 * Clears the entire local storage
 * @function removeFromStorage
 * @param {string} dataEntryPeriod
 * @returns {void}
 */
export const removeFromStorage = (key) => window.localStorage.removeItem(key);

/**
 * @function searchStorage
 * @description Search entire local storage by provided seachString.
 * @param {string} searchString
 * @returns {Array.<string>}
 */
export const searchStorage = (searchString) => {
  const searchResults = [];
  const storage = window.localStorage;
  
  Object.keys(storage).forEach((key) => {
    if (key.includes(searchString)) {
      searchResults.push(key);
    }
  });

  return searchResults;
};
