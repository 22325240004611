const LOGIN_IDS = {
  username: 'userId',
  password: 'userPassword',
  loginButton: 'loginButton',
  rememberMeButton: 'rememberMe'
};

const TIME_ENTRY_IDS = {
  logoutButton: 'logoutButton',
  refreshButton: 'refreshButton',

  resetButton: 'resetButton',
  nextWeekButton: 'nextWeekButton',
  previousWeekButton: 'previousWeekButton',
  previousDayButton: 'previousDay',
  addButton: 'addButton',
  editButton: 'editButton',

  dateInput: 'date',
  woInput: 'woNumber',
  aaInput: 'aaType',
  wageInput: 'wageType',
  hoursInput: 'hours',
  unitsInput: 'units',
  milesInput: 'miles',
  startInput: 'startTime',
  endInput: 'endTime',
  shortTextInput: 'shortText',
  valBasisInput: 'valBasis',
  ocCodeInput: 'ocCode',

  submitButton: 'submitButton',
  summaryButton: 'summaryButton',

  okButton: 'okButton',
  cancelButton: 'cancelButton'
};

module.exports = { TIME_ENTRY_IDS, LOGIN_IDS };
