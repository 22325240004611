import * as React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Spinner = ({ className }) => (
  <span id='spinner-container' className={classNames('spinner', className)}>
    <div id='spinner-icon' />
  </span>
);

export default Spinner;
