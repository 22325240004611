import axios from 'axios';
import { API_ENDPOINT } from '../constants/urls';

const axiosInstance = (credentials) => {
  const instance = axios.create({
    timeout: 10000,
    headers: {
      Authorization: `Basic ${credentials}`
    },
    responseType: 'json'
  });

  if (API_ENDPOINT) {
    instance.defaults.baseURL = API_ENDPOINT;
  }

  return instance;
};

export const post = (credentials, url, data) =>
  axiosInstance(credentials)({
    url,
    data,
    method: 'post'
  });

export const get = (credentials, url) =>
  axiosInstance(credentials)({
    url,
    method: 'get'
  });
