import * as React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, redirectTo, getTokenCallback, ...props }) => {
  const hasToken = getTokenCallback();
  return (
    <Route
      {...props}
      render={() =>
        hasToken ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  redirectTo: PropTypes.string,
  getTokenCallback: PropTypes.func
};

export default ProtectedRoute;
