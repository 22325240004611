import { fromStorage, toStorage, removeFromStorage } from '../utils/storage';
import '../types';

const USER_ID = 'userId';
const SIGNED_IN_TOKEN = 'signedIn';

/**
 * @function getSignInToken
 * @returns {string}
 */
export const getSignInToken = () => fromStorage(SIGNED_IN_TOKEN);

/**
 * @function isUserSignedIn
 * @returns {boolean}
 */
export const isUserSignedIn = () => getSignInToken();

/**
 * @function getUserId
 * @returns {string}
 */
export const getUserId = () => fromStorage(USER_ID);

/**
 * @function setSignInToken
 * @param {boolean} signedIn
 * @returns {void}
 */
export const setSignInToken = (signedIn) => toStorage(SIGNED_IN_TOKEN, signedIn);

/**
 * Stores a user's ID as a token.
 * @param {string} userId
 * @param {string} credentials
 */
export const storeSignIn = (userId, rememberUser) => {
  if (rememberUser) {
    toStorage(USER_ID, userId);
  } else {
    removeFromStorage(USER_ID);
  }
  toStorage(SIGNED_IN_TOKEN, true);
};

/**
 * Removes a user's signed-in token.
 */
export const removeSignIn = () => {
  removeFromStorage(USER_ID);
  removeFromStorage(SIGNED_IN_TOKEN);
};
