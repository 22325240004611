import * as React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { Swipeable } from 'react-swipeable';

import { maxTimeSkipError } from '../../constants/errors';
import { ROUTES } from '../../constants/routes';
import { skipDataEntryPeriod } from '../../utils/dates';
import AnimatedModal from '../Common/AnimatedPopupModal';
import Spinner from '../Common/Spinner';
import Header from './Header';
import InfoContent from './InfoContent';
import Footer from './Footer';

import './styles.scss';

const swipableConfig = {
  delta: 200, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: true, // track mouse input
  rotationAngle: 0 // set a rotation angle
};

const NavigationView = ({
  actions,
  userId,
  credentials,
  signedIn,
  unsubmittedCount,
  warningCount,
  totalHours,
  modalMessage,
  modalConfirmHandler,
  optionalModalHandler,
  dataEntryPeriod,
  dataInFlight,
  online,
  children
}) => {
  const [showModalMessage, setShowModalMessage] = React.useState(false);

  const history = useHistory();

  const handleNavigation = (url) => {
    actions.emitSetEditingEntry({});
    history.push(url);
  };

  window.onbeforeunload = () => {
    actions.emitSignOut();
  };

  window.addEventListener('offline', () => {
    actions.emitSetOnline(false);
  });

  window.addEventListener('online', () => {
    actions.emitSetOnline(true);
  });

  React.useEffect(() => {
    if (!signedIn) {
      actions.emitSignOut();
      handleNavigation(ROUTES.LOGIN);
    } else if (signedIn && online && isEmpty(credentials)) {
      const confirmHandler = () => {
        actions.emitSignOut();
        handleNavigation(ROUTES.LOGIN);
      };
      actions.emitSetModal('You are now online, please sign in.', confirmHandler, false);
    }
  }, [signedIn, online]);

  React.useEffect(() => {
    actions.emitSetOnline(window.navigator.onLine);
  }, []);

  React.useEffect(() => {
    setShowModalMessage(!isEmpty(modalMessage));
  }, [modalMessage]);

  React.useEffect(() => {
    if (!showModalMessage) {
      actions.emitSetModalConfirmHandler(undefined);
    }
  }, [showModalMessage]);

  const handleSignOut = () => {
    const handler = () => {
      actions.emitSignOut();
      handleNavigation(ROUTES.ROOT);
      actions.emitSetModalConfirmHandler(undefined);
    };
    const message = 'Are you sure you want to log out?';
    
    actions.emitSetModal(message, handler);
  };

  const handleRefresh = (fallbackDataEntryPeriod) => {
    actions.emitGetEntries({ fallbackDataEntryPeriod });
    actions.emitSetEditingEntry({});
  };

  const handleSubmitEntries = (ignoreWarnings = false) => {
    if (online) {
      const ignoredWarningsValue = ignoreWarnings ? 'I' : '';
      const submitMessage = `You have network connectivity. Are you sure you want to ${ignoreWarnings
        ? 'ignore warnings and submit entries to SAP?'
        : 'submit entries to SAP?'}`;
      const confirmHandler = () => {
        actions.emitSubmitEntries(ignoredWarningsValue);
        actions.emitSetEditingEntry({});
      };
      actions.emitSetModal(submitMessage, confirmHandler);
    } else {
      actions.emitSetModal('You do not have network connectivity. Please try again later.');
    }
  };

  const setDataPeriod = (week, timeskipMaxDate, fallbackDataEntryPeriod) => {
    if (week) {
      actions.emitSetDataEntryPeriod(week);
    } else {
      actions.emitSetModal(maxTimeSkipError(timeskipMaxDate));
    }
    handleRefresh(fallbackDataEntryPeriod);
  };

  const setPreviousWeek = () => {
    const week = skipDataEntryPeriod(dataEntryPeriod.StartDate, false);
    setDataPeriod(week, dataEntryPeriod.StartDate, dataEntryPeriod);
  };

  const setNextWeek = () => {
    const week = skipDataEntryPeriod(dataEntryPeriod.StartDate, true);
    setDataPeriod(week, dataEntryPeriod.EndDate, dataEntryPeriod);
  };

  const closeModalMessage = () => {
    actions.emitSetModal('');
  };

  return (
    <Swipeable onSwipedLeft={setNextWeek} onSwipedRight={setPreviousWeek} {...swipableConfig}>
      <div id='app-root' className='main-app-container'>
        <div id='app-body' className='main-app-container'>
          {dataInFlight && <Spinner />}
          <AnimatedModal
            open={showModalMessage}
            title='Time Entry App'
            message={modalMessage}
            optionalHandler={optionalModalHandler}
            onClose={closeModalMessage}
            confirmHandler={modalConfirmHandler}
          />
          <Header showsLogoutButtons={signedIn} onLogout={handleSignOut} onRefresh={handleRefresh} />
          {signedIn && (
            <InfoContent
              userId={userId}
              dataEntryPeriod={dataEntryPeriod}
              handleNavigation={handleNavigation}
              setNextWeek={setNextWeek}
              setPreviousWeek={setPreviousWeek}
            />
          )}
          {children}
          <Route
            exact
            path={ROUTES.DASHBOARD}
            render={() => (
              <Footer
                handleNavigation={handleNavigation}
                submitEntries={handleSubmitEntries}
                warningCount={warningCount}
                unsubmittedCount={unsubmittedCount}
                totalHours={totalHours}
              />
            )}
          />
        </div>
      </div>
    </Swipeable>
  );
};

export default NavigationView;
