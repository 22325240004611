export const TIME_ENTRY_STATE = {
  // unsubmitted, white
  WHITE: 'WHITE',
  // unsubmitted with errors -- but acceptable to SAP, yellow
  YELLOW: 'YELLOW',
  // submitted with errors, user must fix before resubmission, red
  RED: 'RED',
  // submitted and accepted by SAP
  GREEN: 'GREEN'
};

export const SUBMISSION_STATUS = {
  REJECTED: 'REJECTED',
  SAVED: 'SAVED',
  WARNINGS: 'WARNINGS',
  NO_DATA: 'NO_DATA',
  NOPERNR: 'NOPERNR',
  INELIGIBLE: 'INELIGIBLE',
  BLANK: ''
};

export const ENTRY_STATUS = {
  APPROVED: 'Approved',
  SAVED: 'Saved',
  DELETED: 'Record Deleted',
  EDITED: 'Record Edited',
  BLANK: ''
};

export const ENTRY_STATUS_MESSAGE_TYPE  = {
  SAVED: 'S',
  ERROR: 'E',
  WARNINGS: 'W'
};

export const AA_CODE =  {
  HOURS: '0100'
};

export const UNIT_WAGE_TYPES = ['1600', '1601'];

export const MILES_WAGE_TYPES = ['1602', '1604'];

export const TABLE_HEADER_NAMES = {
  DATE: 'Date',
  WO_NUMBER: 'WO Number',
  AATYPE: 'A/A Type',
  WAGE_TYPE: 'Wage Type',
  UNITS: 'Units',
  TIME_PERIOD: 'Time Period',
  VAL_BASIS: 'Val Basis',
  OC_CODE: 'OC Code',
  SHORT_TEXT: 'Short Text',
  ACTIONS: 'Actions'
};

export const SUMMARY_TABLE_HEADER_NAMES = {
  WO: 'WO Number',
  TYPE: 'Type [Activity/Wage]',
  TOTAL: 'Total'
};
