import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { Toolbar, Modal, Backdrop, Fade, Card, CardActions, CardContent, Typography } from '@material-ui/core';

import { TIME_ENTRY_IDS } from '../../../constants/elementIds';

import StyledButton from '../Button';

import './styles.scss';

const AnimatedModal = ({ open, title, message, onClose, confirmHandler, optionalHandler }) => {
  let confirmLabel = 'Ok';
  let cancelLabel = 'Cancel';

  if (!isNil(confirmHandler) && optionalHandler) {
    confirmLabel = 'Yes';
    cancelLabel = 'No';
  }

  const handleOk = () => {
    if (!isNil(confirmHandler)) {
      confirmHandler();
    }
    onClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className='modal'
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 150
        }}
      >
        <Fade in={open}>
          <Card className='modal-container'>
            {open && (
              <>
                <Toolbar className='title-container'>
                  <Typography className='title' color='textPrimary' component='div'>
                    {title}
                  </Typography>
                </Toolbar>
                <CardContent className='content'>
                  <Typography variant='body2' component='div'>
                    {message}
                  </Typography>
                </CardContent>
                <CardActions className='modal-actions'>
                  <StyledButton id={TIME_ENTRY_IDS.okButton} size='small' variant='contained' onClick={handleOk}>
                    {confirmLabel}
                  </StyledButton>
                  {confirmHandler && optionalHandler && (
                    <StyledButton
                      id={TIME_ENTRY_IDS.cancelButton}
                      size='small'
                      variant='contained'
                      onClick={onClose}
                      secondary
                    >
                      {cancelLabel}
                    </StyledButton>
                  )}
                </CardActions>
              </>
            )}
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

AnimatedModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  optionalHandler: PropTypes.bool,
  confirmHandler: PropTypes.func
};

export default AnimatedModal;
