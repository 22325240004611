import moment from 'moment';

// Returns an array of moments starting on Monday
// and going until the next sunday
export const getDaysOfWeek = (currentDate) => {
  // Days of isoWeek
  const days = [1, 2, 3, 4, 5, 6, 7];
  const date = moment(currentDate);

  // Gets the most recent monday (start of isoweek)
  return days.map(day => moment(date.isoWeekday(day)));
};

// returns an object to be used for drop downs ex {name: 08/22 Mon, value: 08/22/20}
// From Monday->Sunday
export const generateDateSelections = (currentDate) => {
  const days = getDaysOfWeek(currentDate);

  return days.map((day) => ({
    name: day.format('MM/DD/YYYY (ddd)').toString(),
    value: day.format('MM/DD/YYYY').toString()
  }));
};

export const generateEmptyDates = (currentDate) => {
  const days = getDaysOfWeek(currentDate);

  return days.map((day) => ({ day: day.format('MM/DD ddd').toString(), hours: 0 }));
};

/**
 * @returns {Object.<string, number>}
 */
export const generateInitializedDates = (currentDate) => {
  const days = getDaysOfWeek(currentDate);
  const dateObj = {};
  days.forEach((day) => {
    dateObj[day.format('MM/DD ddd').toString()] = 0;
  });
  return dateObj;
};

export const generateDateWeekSAPFormat = (currentDate) => {
  const days = getDaysOfWeek(currentDate);

  return days.map((day) => day.format('YYYY-MM-DD').toString());
};

// SAP uses a different formati for dates: (YYYY-MM-DD)
export const defaultDataEntryPeriod = () => {
  const start = moment().startOf('isoWeek').format('YYYY-MM-DD').toString();
  const end = moment().endOf('isoWeek').format('YYYY-MM-DD').toString();

  return { StartDate: start, EndDate: end };
};

export const skipDataEntryPeriod = (current, next) => {
  const action = next ? 'add' : 'subtract';
  const start = moment(current)[action](7, 'd').startOf('isoWeek').format('YYYY-MM-DD').toString();
  const end = moment(current)[action](7, 'd').endOf('isoWeek').format('YYYY-MM-DD').toString();

  const maxTimeSkip = moment()[action](28, 'd').startOf('isoWeek').format('YYYY-MM-DD').toString();

  if (start === maxTimeSkip) {
    return undefined;
  }
  return { StartDate: start, EndDate: end };
};

/**
 * @function getDataEntryPeriod
 * @param {number} weekOffset integet to indicate amount of weeks to skip [negative for backwards]
 * @returns {DataEntryPeriod}
 */
export const getDataEntryPeriod = (weekOffset) => {
  const weekMultiple = Math.abs(7 * weekOffset);
  const action = weekOffset >= 0 ? 'add' : 'subtract';

  const timePeriod = {
    StartDate: moment()[action](weekMultiple, 'd').startOf('isoWeek').format('YYYY-MM-DD').toString(),
    EndDate: moment()[action](weekMultiple, 'd').endOf('isoWeek').format('YYYY-MM-DD').toString()
  };

  return timePeriod;
};

/**
 * @function getAllDataEntryPeriods Gets all future and past data entry periods excluding current
 * @returns {Array.<DataEntryPeriod>}
 */
export const getAllDataEntryPeriods = () => {
  const dataEntryPeriods = [];
  for (let i = 1; i <= 3; i++) {
    // Next Week
    dataEntryPeriods.push(getDataEntryPeriod(i));
    // Previous Week
    dataEntryPeriods.push(getDataEntryPeriod(-i));
  }

  return dataEntryPeriods;
};

export const currentWeekRange = (dataEntryPeriod) => {
  const start = moment(dataEntryPeriod.StartDate).format('MM/DD/YYYY');
  const end = moment(dataEntryPeriod.EndDate).format('MM/DD/YYYY');

  return `${start} - ${end}`;
};

export const getToday = () => moment().format('MM/DD/YYYY').toString();

export const getDateFromValue = (value) => value?.split(' ')[0] ?? value;

/**
 * @param {string} defaultValue
 * @returns {Object}
 */
export const generateTimeKeys = (defaultValue) => {
  let Hour = '00';
  let Minute = '00';
  if (defaultValue && defaultValue.includes(':')) {
    /**
     * @type {Array.<string>}
     */
    const [HourValue, MinuteValue] = defaultValue.split(':');
    Hour = HourValue;
    Minute = MinuteValue;
  }
  return {
    Hour,
    Minute
  };
};

export const generateTimeValues = () => ({
  Hour: [
    '',
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24'
  ],
  Minute: ['', '00', '15', '30', '45']
});
