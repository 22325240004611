import { flatten } from 'ramda';
import { fromStorage, toStorage, generateDEPString, removeFromStorage, searchStorage } from '../utils/storage';

import { getDataEntryPeriod as getEntryPeriod } from '../utils/dates';

import '../types';

const TIMECARD = 'TimeCard';

/**
 * @function getTimecard
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {TimeCard}
 */
export const getTimecard = (dataEntryPeriod, userId) =>
  JSON.parse(fromStorage(`${userId}-${TIMECARD}-${generateDEPString(dataEntryPeriod)}`, '{}'));

/**
 * @param {DataEntryPeriod} dataEntryPeriod
 * @param {TimeCard} timecardData
 * @returns {any}
 */
export const setTimecard = (timecardData, dataEntryPeriod, userId) =>
  toStorage(`${userId}-${TIMECARD}-${generateDEPString(dataEntryPeriod)}`, JSON.stringify(timecardData));

/**
 * @function getSavedEntries
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {Array.<Entry>}
 */
export const getSavedEntries = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).LineItems ?? [];

/**
 * @function getActivityTypes
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {Array.<ActivityType>}
 */
export const getActivityTypes = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).ActivityTypes ?? [];

/**
 * @function getOvertimeTypes
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {Array.<OverTimeType>}
 */
export const getOvertimeTypes = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).OverTimeTypes ?? [];

/**
 * @function getWageTypes
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {Array.<WageType>}
 */
export const getWageTypes = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).WageTypes ?? [];

/**
 * @function getDataEntryPeriod
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {DataEntryPeriod}
 */
export const getDataEntryPeriod = (dataEntryPeriod, userId) =>
  getTimecard(dataEntryPeriod, userId).DataEntryPeriod ?? {};

/**
 * @function getWorkScheduleHours
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {Array.<WorkScheduleHours>}
 */
export const getWorkScheduleHours = (dataEntryPeriod, userId) =>
  getTimecard(dataEntryPeriod, userId).WorkScheduleHours ?? [];

/**
 * @function getApplicationId
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {string}
 */
export const getApplicationId = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).ApplicationId ?? '';

/**
 * @function getCorrelationId
 * @param {DataEntryPeriod} dataEntryPeriod
 * @returns {string}
 */
export const getCorrelationId = (dataEntryPeriod, userId) => getTimecard(dataEntryPeriod, userId).CorrelationId ?? '';

/**
 * @function cleanExpiredTimecards
 * @description Deletes expired timecard data from local storage up to 5 weeks prior to valid period
 * @returns {void}
 */
export const cleanExpiredTimecards = () => {
  const expiredEntryPeriods = [
    generateDEPString(getEntryPeriod(-4)),
    generateDEPString(getEntryPeriod(-5)),
    generateDEPString(getEntryPeriod(-6)),
    generateDEPString(getEntryPeriod(-7)),
    generateDEPString(getEntryPeriod(-8))
  ];

  const expiredStorageKeys = flatten(expiredEntryPeriods.map(searchStorage));

  expiredStorageKeys.forEach(removeFromStorage);
};
