/* eslint camelcase:0 */
import { handleActions } from 'redux-actions';

import { defaultDataEntryPeriod } from '../utils/dates';

import '../types';

/*
  -------
  action types
  -------
*/

export const EMIT_SET_ACTIVITY_TYPES = 'EMIT_SET_ACTIVITY_TYPES';
export const EMIT_SET_DATA_ENTRY_PERIOD = 'EMIT_SET_DATA_ENTRY_PERIOD';
export const EMIT_SET_OVERTIME_TYPES = 'EMIT_SET_OVERTIME_TYPES';
export const EMIT_SET_TIME_CARD_STATUS = 'EMIIT_SET_TIME_CARD_STATUS';
export const EMIT_SET_WAGE_TYPES = 'EMIT_SET_WAGE_TYPES';
export const EMIT_SET_WORK_SCHEDULE_HOURS = 'EMIT_SET_WORK_SCHEDULE_HOURS';
export const EMIT_SET_CORRELATION_ID = 'EMIT_SET_CORRELATION_ID';
export const EMIT_SET_APPLICATION_ID = 'EMIT_SET_APPLICATION_ID';
export const EMIT_SET_OVERALL_TIME_CARD_STATUS = 'EMIT_SET_OVERALL_TIME_CARD_STATUS';

/*
  --------
  creators
  --------
*/

export const emitSetActivityTypes = (activityTypes) => ({
  type: EMIT_SET_ACTIVITY_TYPES,
  payload: activityTypes
});

export const emitSetDataEntryPeriod = (dataEntryPeriod) => ({
  type: EMIT_SET_DATA_ENTRY_PERIOD,
  payload: dataEntryPeriod
});

export const emitSetOvertimeTypes = (overTimeTypes) => ({
  type: EMIT_SET_OVERTIME_TYPES,
  payload: overTimeTypes
});

export const emitSetTimeCardStatus = (timeCardStatus) => ({
  type: EMIT_SET_TIME_CARD_STATUS,
  payload: timeCardStatus
});

export const emitSetWageTypes = (wageTypes) => ({
  type: EMIT_SET_WAGE_TYPES,
  payload: wageTypes
});

export const emitSetWorkScheduleTypes = (workScheduleHours) => ({
  type: EMIT_SET_WORK_SCHEDULE_HOURS,
  payload: workScheduleHours
});

/**
 * 描述
 * @date 2020-08-24
 * @param {TimeCard} timecard
 * @returns {void}
 */
export const emitSetTimecard = (timecard) => (dispatch) => {
  dispatch({
    type: EMIT_SET_ACTIVITY_TYPES,
    payload: timecard.ActivityTypes
  });
  dispatch({
    type: EMIT_SET_DATA_ENTRY_PERIOD,
    payload: timecard.DataEntryPeriod
  });
  dispatch({
    type: EMIT_SET_OVERTIME_TYPES,
    payload: timecard.OverTimeTypes
  });
  dispatch({
    type: EMIT_SET_TIME_CARD_STATUS,
    payload: timecard.TimeCardStatus
  });
  dispatch({
    type: EMIT_SET_WAGE_TYPES,
    payload: timecard.WageTypes
  });
  dispatch({
    type: EMIT_SET_WORK_SCHEDULE_HOURS,
    payload: timecard.WorkScheduleHours
  });
  dispatch({
    type: EMIT_SET_APPLICATION_ID,
    payload: timecard.ApplicationId
  });
  dispatch({
    type: EMIT_SET_CORRELATION_ID,
    payload: timecard.CorrelationId
  });
  dispatch({
    type: EMIT_SET_OVERALL_TIME_CARD_STATUS,
    payload: timecard.OverAllTimeCardStatus
  });
};

/*
  -------
  reducer
  -------
*/

/**
 * @type {{
 *  activityTypes: Array.<ActivityType>,
 *  dataEntryPeriod: DataEntryPeriod,
 *  overTimeTypes: Array.<OverTimeType>,
 *  wageTypes: Array.<WageType>,
 *  workScheduleHours: Array.<WorkScheduleHours>,
 *  timeCardStatus: TimeCardStatus,
 *  applicationId: string,
 *  correlationId: string,
 *  OverAllTimeCardStatus: {Message: string, Code: string}
 * }}
 */
export const INITIAL_STATE = {
  activityTypes: [],
  dataEntryPeriod: defaultDataEntryPeriod(),
  overTimeTypes: [],
  wageTypes: [],
  workScheduleHours: [],
  timeCardStatus: '',
  applicationId: '',
  correlationId: '',
  OverAllTimeCardStatus: {}
};

export default handleActions(
  {
    [EMIT_SET_WORK_SCHEDULE_HOURS]: (state, action) => ({
      ...state,
      workScheduleHours: action.payload
    }),
    [EMIT_SET_ACTIVITY_TYPES]: (state, action) => ({
      ...state,
      activityTypes: action.payload
    }),
    [EMIT_SET_DATA_ENTRY_PERIOD]: (state, action) => ({
      ...state,
      dataEntryPeriod: action.payload
    }),
    [EMIT_SET_OVERTIME_TYPES]: (state, action) => ({
      ...state,
      overTimeTypes: action.payload
    }),
    [EMIT_SET_TIME_CARD_STATUS]: (state, action) => ({
      ...state,
      timeCardStatus: action.payload
    }),
    [EMIT_SET_WAGE_TYPES]: (state, action) => ({
      ...state,
      wageTypes: action.payload
    }),
    [EMIT_SET_APPLICATION_ID]: (state, action) => ({
      ...state,
      applicationId: action.payload
    }),
    [EMIT_SET_CORRELATION_ID]: (state, action) => ({
      ...state,
      correlationId: action.payload
    }),
    [EMIT_SET_OVERALL_TIME_CARD_STATUS]: (state, action) => ({
      ...state,
      OverAllTimeCardStatus: action.payload
    })
  },
  INITIAL_STATE
);
