import { defaultDataEntryPeriod } from '../utils/dates';

export const DEFAULT_ACTIVITY_TYPES = [
  { AACode: '0100', ShortText: 'Time Worked' },
  { AACode: '0116', ShortText: 'MOT Time Wkd Non-DTE Work' },
  { AACode: '0117', ShortText: 'Mutual Aid - Non DTE Work' },
  { AACode: '0120', ShortText: 'Overtime Unpaid' },
  { AACode: '0121', ShortText: 'Unpaid OT Due To FML' },
  { AACode: '0135', ShortText: 'Part Time Over Standard' },
  { AACode: '0150', ShortText: 'Time Wrkd Repl Even Shift' },
  { AACode: '0155', ShortText: 'Time Wrkd Rpl Night Shift' },
  { AACode: '0425', ShortText: 'Course Instructor' },
  { AACode: '0430', ShortText: 'Course Attendance' },
  { AACode: '1306', ShortText: 'Overtime Paid at 1x' },
  { AACode: '1320', ShortText: 'On Call' },
  { AACode: '1326', ShortText: 'Holiday Worked 1.5x' },
  { AACode: '1341', ShortText: 'Cat. Storm Overtime 1.5X' },
  { AACode: '1402', ShortText: 'Absence Sick Family FML' },
  { AACode: '1404', ShortText: 'Absence Sick Fam Non-FML' },
  { AACode: '1406', ShortText: 'Absence Sick Self FML' },
  { AACode: '1408', ShortText: 'Absence Sick Self Non-FML' },
  { AACode: '1412', ShortText: 'DCM Absence' },
  { AACode: '1428', ShortText: 'Ext Dis Sick Self FML' },
  { AACode: '1430', ShortText: 'ExtDis SickSelf NonFML MG' },
  { AACode: '1432', ShortText: 'Funeral' },
  { AACode: '1434', ShortText: 'Holiday' },
  { AACode: '1436', ShortText: 'Jury Duty' },
  { AACode: '1446', ShortText: 'Leave of Absence Unpaid' },
  { AACode: '1456', ShortText: 'Occupational Injury' },
  { AACode: '1458', ShortText: 'Personal' },
  { AACode: '1460', ShortText: 'Personal Sick Fam FML' },
  { AACode: '1462', ShortText: 'Personal Sick Fam Non-FML' },
  { AACode: '1464', ShortText: 'Personal Sick Self FML' },
  { AACode: '1466', ShortText: 'Personal Sick Self NonFML' },
  { AACode: '1470', ShortText: 'Pregnancy Absence NonFML' },
  { AACode: '1471', ShortText: 'Unpaid Pregnancy Non-FML' },
  { AACode: '1472', ShortText: 'Pregnancy Ext Dis NonFML' },
  { AACode: '1476', ShortText: 'President Approved' },
  { AACode: '1484', ShortText: 'Rest Period (dur reg hrs)' },
  { AACode: '1510', ShortText: 'Military-Temp<=30 CalDays' },
  { AACode: '1511', ShortText: 'Military-Actv 30+ CalDays' },
  { AACode: '1516', ShortText: 'Unpaid Sick Family FML' },
  { AACode: '1518', ShortText: 'Unpaid Sick Family NonFML' },
  { AACode: '1520', ShortText: 'Unpaid Sick Self FML' },
  { AACode: '1522', ShortText: 'Unpaid Sick Self Non-FML' },
  { AACode: '1524', ShortText: 'Vacation' },
  { AACode: '1526', ShortText: 'Vacation Sick Family FML' },
  { AACode: '1528', ShortText: 'Vacation Sick Fam NonFML' },
  { AACode: '1530', ShortText: 'Vacation Sick Self FML' },
  { AACode: '1532', ShortText: 'Vacation Sick Self NonFML' },
  { AACode: '1534', ShortText: 'PermissibleAbsence Unpaid' },
  { AACode: '1536', ShortText: 'NonPermissible Abs Unpaid' },
  { AACode: '1537', ShortText: 'Tardy' },
  { AACode: '1538', ShortText: 'Grandfathered FML 90%' },
  { AACode: '1540', ShortText: 'Grandfathered Non-FML 90%' },
  { AACode: '1551', ShortText: 'CTO' },
  { AACode: '1552', ShortText: 'CTO Sick Self FML' },
  { AACode: '1553', ShortText: 'CTO Sick Family FML' },
  { AACode: '1554', ShortText: 'CTO Managed Sick Self' },
  { AACode: '1555', ShortText: 'CTO Pregnancyick Self' },
  { AACode: '1556', ShortText: 'CTO Sick Self Non-FML' },
  { AACode: '1557', ShortText: 'CTO Sick Family Non-FML' },
  { AACode: '1558', ShortText: 'Unpaid Managed Sick Self' },
  { AACode: '1559', ShortText: 'ExtDis SickSelf NonFML' },
  { AACode: '1560', ShortText: 'CTO Personal' },
  { AACode: '2525', ShortText: 'Company Initiated' }
];

export const DEFAULT_WAGE_TYPES = [
  { WageType: '1301', ShortText: 'TimeWkd No Lunch' },
  { WageType: '1600', ShortText: 'Meal Allowance' },
  { WageType: '1601', ShortText: 'Commuting Fee TB' },
  { WageType: '1602', ShortText: 'Casual Mileage' },
  { WageType: '1604', ShortText: 'Prem Mileage' },
  { WageType: '1656', ShortText: 'Hazwopper' },
  { WageType: '1657', ShortText: 'OQ Training GU' },
  { WageType: '1662', ShortText: 'Overtime Bypass' },
  { WageType: '1670', ShortText: 'Loc39 Prebrf 1.5x' }
];

export const DEFAULT_OVERTIME_TYPES = [
  { Description: 'Single Area Premium', OverTimeType: '1' },
  { Description: 'Double Area Premium', OverTimeType: '2' },
  { Description: 'Triple Area Premium', OverTimeType: '3' },
  { Description: 'Quadruple Area Premium', OverTimeType: '4' }
];

export const DEFAULT_TIMECARD_RESPONSE = {
  data: {
    TimeCard: {
      ApplicationId: 'TEA',
      CorrelationId: '',
      WorkScheduleHours: [],
      OverAllTimeCardStatus: {},
      DataEntryPeriod: defaultDataEntryPeriod(),
      LineItems: [],
      ActivityTypes: DEFAULT_ACTIVITY_TYPES,
      WageTypes: DEFAULT_WAGE_TYPES,
      OverTimeTypes: DEFAULT_OVERTIME_TYPES
    }
  }
};

