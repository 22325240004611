import { v4 } from 'uuid';

/**
 * Returns the a newly-generated ID.
 */
export const generateUUID = () => {
  const uuid = v4().replace(/-/g, '');
  return `${uuid.substring(0, 6)}-${uuid.substring(6, 12)}-${uuid.substring(12, 18)}`;
};

export const isIterable = (obj) => {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}
