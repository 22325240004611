import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const TextHighlightBox = ({value}) => (
    <div className='text-highlight-box-container'>
        <span className='text-highlight-text'>{value}</span>
    </div>
)

TextHighlightBox.propTypes = {
    value: PropTypes.string.isRequired
}

export default TextHighlightBox;
