import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import history from '../config/history';

import { ROUTES } from '../constants/routes';

import ProtectedRoute from './Common/Route/ProtectedRoute';
import NavigationContainer from './Navigation/Container';
import NotFound from './Common/Route/NotFound';
import Spinner from './Common/Spinner';
import { isUserSignedIn } from '../LocalStorage';

const { lazy, Suspense } = React;

const LoginContainer = lazy(() => import('./Login/Container'));
const MainContanier = lazy(() => import('./Main/Container'));
const TimeEntryContanier = lazy(() => import('./TimeEntry/Container'));
const EntrySummaryContanier = lazy(() => import('./EntrySummary/Container'));

const Routes = ({ store }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <NavigationContainer>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={ROUTES.ROOT} component={MainContanier} />
            <Route exact path={ROUTES.LOGIN} component={LoginContainer} />
            <ProtectedRoute
              exact
              path={ROUTES.SUMMARY}
              component={EntrySummaryContanier}
              redirectTo={ROUTES.LOGIN}
              getTokenCallback={isUserSignedIn}
            />
            <ProtectedRoute
              exact
              path={ROUTES.DASHBOARD}
              component={TimeEntryContanier}
              redirectTo={ROUTES.LOGIN}
              getTokenCallback={isUserSignedIn}
            />
            <Route path='*' component={NotFound} />
          </Switch>
        </Suspense>
      </NavigationContainer>
    </ConnectedRouter>
  </Provider>
);

export default Routes;
