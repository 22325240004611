import * as React from 'react';
import moment from 'moment';

/**
 * @typedef ErrorType
 * @property {string} INVALID_HOURS
 * @property {string} UNKNOWN
 */

/**
 * @type {ErrorType}
 */
export const ERROR_TYPE = {
  INVALID_HOURS: 'INVALID_HOURS',
  UNKNOWN: 'UNKNOWN'
};

/**
 * @param {ErrorType} error
 * @param {string} option
 * @returns {any}
 */
export const getError = (error, option) =>
  ({
    INVALID_HOURS: `You must enter the time frame for all hours worked before the Timesheet is submitted. Time must be in 2400 format (i.e. 1530-2330) Following dates have more than 8 hrs: ${
      option ?? ''
    }`,
    UNKNOWN: 'There was a problem with your request. Please contact the IT Help Desk.'
  }[error]);

export const maxTimeSkipError = (dataPeriod) =>
  `Timesheet information after ${moment(dataPeriod).format(
    'MM/DD/YYYY'
  )} is not available in the time entry app. Please use the SAP application to access this information.`;

export const getErrorMessageFromCode = (
  code,
  defaultMessage = 'There was a problem with your request. Please contact the IT Help Desk.'
) =>
  ({
    0: 'Connection Error. Verify Network Connection and Try Again.',
    401: 'Your User ID or password is not valid. Please try again later.',
    403: 'Your User ID is not permitted access to this application. Please contact the IT Help Desk.',
    404: 'Connection to SAP not available. Please try again later.',
    500: 'There was a problem with your request. Please contact the IT Help Desk.'
  }[code] ?? defaultMessage);

export const generateLocalSaveError = (failedDates) => (
  <>
    Could not save the following time periods into the local database:
    {failedDates.map((date) => (
      <>
        <br />
        {date}
      </>
    ))}
  </>
);

export const getTimeCardErrors = (error, message = '') => ({
  NO_DATA:  `Error from SAP. ${message}`,
  NOPERNR:  'Your userid is not present in SAP database. Please contact your Supervisor',
  INELIGIBLE:  'You are not eligible to submit TimeCard. Please contact your Supervisor.'
}[error])
