import { post } from './service';
import { defaultDataEntryPeriod } from '../utils/dates';

export const login = (userId, userCredentials, DataEntryPeriod = defaultDataEntryPeriod()) => {
  const data = {
    TimeCard: {
      EmployeeId: userId.toUpperCase(),
      DataEntryPeriod,
      ApplicationId: 'TEA',
      WageType: 'X',
      ActivityTypes: 'X',
      OverTimeTypes: 'X',
      WorkScheduleHours: 'X'
    }
  };
  return post(userCredentials, '?actionCode=display', data);
}
