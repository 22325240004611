import { handleActions } from 'redux-actions';
import { isEmpty, isNil } from 'ramda';

import {
  storeSignIn,
  setTimecard,
  setSignInToken,
  getTimecard,
  cleanExpiredTimecards,
  clearDuplicateEntries
} from '../LocalStorage';
import {
  ERROR_TYPE,
  DEFAULT_TIMECARD_RESPONSE,
  SUBMISSION_STATUS,
  generateLocalSaveError,
  getErrorMessageFromCode,
  getTimeCardErrors,
  getError
} from '../constants';
import { getBase64Credentials } from '../utils/user';
import { getAllDataEntryPeriods } from '../utils/dates';
import { login } from '../services/login';

// eslint-disable-next-line import/no-cycle
import { emitSetEntries } from './entries';
// eslint-disable-next-line import/no-cycle
import { emitSetTimecard } from './timecard';

import '../types';

/** @type {TimeCardResponse} */
let response;

/*
  -------
  action types
  -------
*/

export const EMIT_SET_SIGN_IN = 'EMIT_SET_SIGN_IN';
export const EMIT_SIGN_IN_FAILURE = 'EMIT_SIGN_IN_FAILURE';
export const EMIT_SET_USERID = 'EMIT_SET_USERID';
export const EMIT_SET_USER_CREDENTIALS = 'EMIT_SET_USER_CREDENTIALS';
export const EMIT_SET_ONLINE = 'EMIT_SET_ONLINE';
export const EMIT_SET_DATA_IN_FLIGHT = 'EMIT_SET_DATA_IN_FLIGHT';
export const EMIT_SET_PASSWORD = 'EMIT_SET_PASSWORD';
export const EMIT_SET_SHOW_DISPLAY_ERROR = 'EMIT_SET_SHOW_DISPLAY_ERROR';
export const EMIT_SET_PICKER_OPEN = 'EMIT_SET_PICKER_OPEN';
export const EMIT_SET_MODAL_OK_HANDLER = 'EMIT_SET_MODAL_OK_HANDLER';
export const EMIT_SET_MODAL_MESSAGE = 'EMIT_SET_MODAL_MESSAGE';
export const EMIT_SET_MODAL_CONFIRM_HANDLER_OPTIONAL = 'EMIT_SET_MODAL_CONFIRM_HANDLER_OPTIONAL';

/*
  --------
  action creators
  --------
*/

export const emitSetSignInInfo = (userId, password, userCredentials, rememberUser) => (dispatch) => {
  storeSignIn(userId, rememberUser);
  dispatch({
    type: EMIT_SET_USERID,
    payload: userId
  });
  dispatch({
    type: EMIT_SET_PASSWORD,
    payload: password
  });
  dispatch({
    type: EMIT_SET_USER_CREDENTIALS,
    payload: userCredentials
  });
  dispatch({
    type: EMIT_SET_SIGN_IN,
    payload: true
  });
};

export const emitSetModal = (message, confirmHandler, optional = true) => (dispatch) => {
  dispatch({
    type: EMIT_SET_MODAL_MESSAGE,
    payload: message
  });
  dispatch({
    type: EMIT_SET_MODAL_OK_HANDLER,
    payload: confirmHandler
  });
  dispatch({
    type: EMIT_SET_MODAL_CONFIRM_HANDLER_OPTIONAL,
    payload: optional
  });
};

/**
 * @param {string} userId
 * @param {string} userCredentials
 * @returns {void}
 */
export const emitSetFuturePastSubmissions = (userId, userCredentials) => async (dispatch) => {
  const dataEntryPeriods = getAllDataEntryPeriods();
  const dataEntryPeriodErrors = [];

  const calls = dataEntryPeriods.map((period) => login(userId, userCredentials, period));

  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < calls.length; i++) {
    /**
     * @type {TimeCardResponse}
     */
    let result;
    try {
      result = await calls[i];
    } catch (error) {
      /**
       * @type {TimeCardData}
       * Request data
       */
      const errorData = error?.response?.config?.data;
      const errorTimePeriod = errorData?.TimeCard?.DataEntryPeriod;
      if (errorTimePeriod) {
        dataEntryPeriodErrors.push(
          `${errorTimePeriod.StartDate.replace('-', '/')} - ${errorTimePeriod.EndDate.replace('-', '/')}`
        );
      }
    }
    if (result) {
      setTimecard(result.data.TimeCard, result.data.TimeCard.DataEntryPeriod, userId);
    }
  }

  if (dataEntryPeriodErrors.length > 0) {
    emitSetModal(generateLocalSaveError(dataEntryPeriodErrors))(dispatch);
  }
};

/**
 * @param {TimeCardResponse} timecardResponse
 * @param {string} userId
 * @param {string} userCredentials
 * @param {boolean} rememberUser
 * @param {boolean} [isLogin]
 * @param {string} [password]
 * @returns {void}
 */
export const emitSetAllData = (
  timecardResponse,
  userId,
  userCredentials,
  rememberUser,
  isLogin = false,
  password = ''
) => async (dispatch, getState) => {
  const {
    application: { online }
  } = getState();

  const timecard = timecardResponse?.data?.TimeCard;
  const status = timecard?.TimeCardStaus;
  const entries = timecard?.LineItems;
  const entryPeriod = timecard?.DataEntryPeriod;

  if (status === SUBMISSION_STATUS.NOPERNR || status === SUBMISSION_STATUS.INELIGIBLE) {
    emitSetModal(getTimeCardErrors(status, timecard.OverAllTimeCardStatus?.Message))(dispatch);
  } else if (!isNil(timecard)) {
    // Set Store
    if (isLogin) {
      emitSetSignInInfo(userId, online ? password : '', online ? userCredentials : '', rememberUser)(dispatch);
    }
    emitSetTimecard(timecard)(dispatch);
    emitSetEntries(entries)(dispatch, getState);

    if (rememberUser) {
      setTimecard(timecard, entryPeriod, userId);
    }

    cleanExpiredTimecards();

    // Saving future and past entries into local storage
    if (online && isLogin && rememberUser) {
      emitSetFuturePastSubmissions(userId, userCredentials)(dispatch);
    }
  } else {
    emitSetModal(getError(ERROR_TYPE.UNKNOWN))(dispatch);
  }
};

export const emitSignIn = (userId, password, rememberUser = false) => async (dispatch, getState) => {
  response = undefined;
  const userCredentials = getBase64Credentials(userId, password);

  const {
    application: { online },
    timecard: { dataEntryPeriod }
  } = getState();

  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: true
  });

  if (online) {
    try {
      response = await login(userId, userCredentials);
    } catch (error) {
      response = {
        errorCode: error?.response?.status ?? 0
      };
    }
  } else {
    const timecardData = getTimecard(dataEntryPeriod, userId);
    if (!isEmpty(timecardData)) {
      response = {
        data: {
          TimeCard: timecardData
        }
      };
    } else {
      response = DEFAULT_TIMECARD_RESPONSE;
    }
  }

  if (!response || !response.data || response.errorCode || response.errorMessage) {
    const errorMessage = getErrorMessageFromCode(
      response?.errorCode,
      response?.errorMessage ?? 'Your User ID or Password is not valid, please try again.'
    );
    emitSetModal(errorMessage)(dispatch);
    // eslint-disable-next-line no-console
    console.error(response);
  } else {
    clearDuplicateEntries(dataEntryPeriod, userId);
    emitSetAllData(response, userId, userCredentials, rememberUser, true, password)(dispatch, getState);
  }
  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: false
  });
};

export const emitSignOut = () => (dispatch) => {
  setSignInToken(false);
  dispatch({
    type: EMIT_SET_USERID,
    payload: ''
  });
  dispatch({
    type: EMIT_SET_PASSWORD,
    payload: ''
  });
  dispatch({
    type: EMIT_SET_SIGN_IN,
    payload: false
  });
  dispatch({
    type: EMIT_SET_USER_CREDENTIALS,
    payload: ''
  });
};

export const emitSetSignIn = (signedIn) => ({
  type: EMIT_SET_SIGN_IN,
  payload: signedIn
});

export const emitSetUserId = (userId) => ({
  type: EMIT_SET_USERID,
  payload: userId
});

export const emitSetUserCredentials = (credentials) => ({
  type: EMIT_SET_USER_CREDENTIALS,
  payload: credentials
});

export const emitSetOnline = (online) => ({
  type: EMIT_SET_ONLINE,
  payload: online
});

export const emitSetIsPickerOpen = (pickerOpen) => ({
  type: EMIT_SET_PICKER_OPEN,
  payload: pickerOpen
});

export const emitSetDataInFlight = (dataInFlight) => ({
  type: EMIT_SET_DATA_IN_FLIGHT,
  payload: dataInFlight
});

export const emitSetModalConfirmHandler = (handler) => ({
  type: EMIT_SET_MODAL_OK_HANDLER,
  payload: handler
});

export const emitSetModalMessage = (message) => ({
  type: EMIT_SET_MODAL_MESSAGE,
  payload: message
});

export const emitSetModalConfirmHandlerOptional = (optional) => ({
  type: EMIT_SET_MODAL_CONFIRM_HANDLER_OPTIONAL,
  payload: optional
});

/*
  -------
  reducer
  -------
*/

const INITIAL_STATE = {
  signedIn: false,
  online: true,
  userId: '',
  password: '',
  userCredentials: '',
  modalMessage: '',
  modalConfirmHandler: undefined,
  modalConfirmHandlerOptional: true,
  isPickerOpen: false,
  dataInFlight: false
};

export default handleActions(
  {
    [EMIT_SET_USERID]: (state, action) => ({
      ...state,
      userId: action.payload
    }),
    [EMIT_SET_PASSWORD]: (state, action) => ({
      ...state,
      password: action.payload
    }),
    [EMIT_SET_SIGN_IN]: (state, action) => ({
      ...state,
      signedIn: action.payload
    }),
    [EMIT_SET_ONLINE]: (state, action) => ({
      ...state,
      online: action.payload
    }),
    [EMIT_SET_USER_CREDENTIALS]: (state, action) => ({
      ...state,
      userCredentials: action.payload
    }),
    [EMIT_SET_MODAL_OK_HANDLER]: (state, action) => ({
      ...state,
      modalConfirmHandler: action.payload
    }),
    [EMIT_SET_PICKER_OPEN]: (state, action) => ({
      ...state,
      isPickerOpen: action.payload
    }),
    [EMIT_SET_MODAL_MESSAGE]: (state, action) => ({
      ...state,
      modalMessage: action.payload
    }),
    [EMIT_SET_MODAL_CONFIRM_HANDLER_OPTIONAL]: (state, action) => ({
      ...state,
      modalConfirmHandlerOptional: action.payload
    }),
    [EMIT_SET_DATA_IN_FLIGHT]: (state, action) => ({
      ...state,
      dataInFlight: action.payload
    })
  },
  INITIAL_STATE
);
