import { post } from './service';
import { unsubmittedEntriesToSubmissionData } from '../mappers/services';
import { getStagedEntries } from '../utils/timeentry';

export const submitTimeCard = (userId, userCredentials, dataEntryPeriod, correlationId, ignoredWarnings) => {
  const stagedEntries = getStagedEntries(dataEntryPeriod, userId);
  const data = unsubmittedEntriesToSubmissionData(
    userId.toUpperCase(),
    dataEntryPeriod,
    correlationId,
    stagedEntries,
    ignoredWarnings
  );

  return post(userCredentials, '?actionCode=TimeCardEntry', data);
};
