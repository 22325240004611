import React from 'react';
import dtelogo from '../../../assets/images/dtelogo.png';
import Button from '../../Common/Button';
import { TIME_ENTRY_IDS } from '../../../constants/elementIds';

import './styles.scss';

const Header = ({ showsLogoutButtons, onLogout, onRefresh }) => (
  <div id='header-container'>
    <div id='logo-container'>
      <img id='dte-logo-header' src={dtelogo} alt='dte-logo' />
    </div>
    <div id='name-container'>
      <h2 id='header-title'>Time Entry App</h2>
      <p id='header-description'>3.1</p>
    </div>
    <div id='header-actions-container'>
      {showsLogoutButtons && (
        <>
          <Button id={TIME_ENTRY_IDS.logoutButton} onClick={onLogout}>
            Logout
          </Button>
          <Button id={TIME_ENTRY_IDS.refreshButton} onClick={onRefresh}>
            Refresh
          </Button>
        </>
      )}
    </div>
  </div>
);

export default Header;
