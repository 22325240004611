/* eslint-disable no-nested-ternary */

import {
  always,
  curry,
  isNil,
  isEmpty,
  tryCatch
} from 'ramda'

/**
 * Parses a JSON string and returns a value. Returns a default value in the case of any empty, nil, or invalid string.
 * @param def A default value.
 * @param str The JSON string to parse.
 */
export const tryParse = curry((defaultValue, str = undefined) =>
  isNil(str) || isEmpty(str) ? defaultValue : tryCatch((s) => JSON.parse(s), always(defaultValue))(str)
);
