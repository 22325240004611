import { hot } from 'react-hot-loader';
import * as React from 'react';
import { render } from 'react-dom';

import * as serviceWorker from './serviceWorker';

import Routes from './components/Routes';
import store from './config/store';

import './index.scss';
import './types';

const node = document.getElementById('root');
const HotReloadableApp = hot(module)(Routes);

render(<HotReloadableApp store={store} />, node);

/**
 * localhost caching needs to  be disabled so files refresh correctly during the development cycle
*/ 
if (process.env.REACT_APP_ENV) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
