import * as React from 'react';

import IconCheck from '../../../assets/icon-check.svg';
import IconHamburger from '../../../assets/icon-hamburger.svg';

import TextHighlightBox from '../../Common/TextHighlightBox';
import StyledButton from '../../Common/Button';
import { ROUTES } from '../../../constants/routes';
import { TIME_ENTRY_IDS } from '../../../constants/elementIds';

import './styles.scss';

const Footer = ({ warningCount, unsubmittedCount, submitEntries, totalHours, handleNavigation }) => {
  const handleSummaryClick = () => handleNavigation(ROUTES.SUMMARY);
  const warningsExist = warningCount > 0;
  const disabled = unsubmittedCount === 0 && !warningsExist;

  return (
    <div id='footer'>
      <div>
        <TextHighlightBox value={`Total Hours: ${totalHours}`} />
      </div>
      <div className='actions'>
        <StyledButton
          id={TIME_ENTRY_IDS.submitButton}
          disabled={disabled}
          icon={<img src={IconCheck} alt='submit' />}
          onClick={() => submitEntries(warningsExist)}
        >
          {warningsExist ? 'Ignore Warnings and Submit' : 'Save'}
        </StyledButton>
        <StyledButton
          id={TIME_ENTRY_IDS.summaryButton}
          icon={<img src={IconHamburger} alt='summary' />}
          onClick={handleSummaryClick}
        >
          Summary
        </StyledButton>
      </div>
    </div>
  );
};
export default Footer;
