import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import applicationReducer from './application';
import entriesReducer from './entries';
import timecardReducer from './timecard';

export default (history) =>
  combineReducers({
    application: applicationReducer,
    entries: entriesReducer,
    timecard: timecardReducer,
    router: connectRouter(history)
  });
