/* eslint camelcase:0 */
import { handleActions } from 'redux-actions';
import { any, equals, isEmpty, isNil, omit } from 'ramda';

import { submitTimeCard } from '../services/timecard';
import { isIterable } from '../utils/object';
import { login } from '../services/login';
import { SUBMISSION_STATUS, ENTRY_STATUS, ENTRY_STATUS_MESSAGE_TYPE } from '../constants/timeentry';
import { DEFAULT_TIMECARD_RESPONSE, getErrorMessageFromCode, getTimeCardErrors } from '../constants';
import {
  getUnsubmittedEntries,
  addUnsubmittedEntry,
  addStagedForEditEntry,
  addStagedForDeletionEntry,
  clearUnsubmittedEntries,
  clearStagedForDeletionEntries,
  clearStagedForEditEntries,
  replaceUnsubmittedEntry,
  replaceStagedForEditEntry,
  replaceStagedEntries,
  removeUnsubmittedEntry,
  removeStagedForEditEntry,
  getStagedForDeletionEntries,
  getStagedForEditEntries,
  setTimecard,
  getTimecard,
  getUserId,
  clearDuplicateEntries
} from '../LocalStorage';

import { EMIT_SET_DATA_ENTRY_PERIOD, EMIT_SET_OVERALL_TIME_CARD_STATUS, EMIT_SET_TIME_CARD_STATUS } from './timecard';
// eslint-disable-next-line import/no-cycle
import { emitSetAllData, emitSetModal, EMIT_SET_DATA_IN_FLIGHT } from './application';

import '../types';

/** @type {TimeCardResponse} */
let response;

/*
  -------
  action types
  -------
*/

export const EMIT_SUBMIT_ENTRY_SUCCESS = 'EMIT_SUBMIT_ENTRY';
export const EMIT_SET_ENTRIES = 'EMIT_SET_ENTRIES';
export const EMIT_SET_EDITING_ENTRY = 'EMIT_SET_EDITING_ENTRY';
export const EMIT_SET_INPUT_FIELDS = 'EMIT_SET_INPUT_FIELDS';
export const EMIT_CLEAR_ENTRIES = 'EMIT_CLEAR_ENTRIES';
export const EMIT_INPUT_UPDATE = 'EMIT_INPUT_UPDATE';
export const EMIT_SET_UNSUBMITTED_COUNT = 'EMIT_SET_UNSUBMITTED_COUNT';
export const EMIT_SET_WARNING_COUNT = 'EMIT_SET_WARNING_COUNT';
export const EMIT_SET_TOTAL_HOURS = 'EMIT_SET_TOTAL_HOURS';
export const EMIT_INPUT_INVALIDATION_UPDATE = 'EMIT_INPUT_INVALIDATION_UPDATE';
export const EMIT_CLEAR_INPUT_FIELDS = 'EMIT_CLEAR_INPUT_FIELDS';
export const EMIT_CLEAR_INPUT_INVALIDATIONS = 'EMIT_CLEAR_INPUT_INVALIDATIONS';
export const EMIT_GET_ENTRIES_SUCCESS = 'EMIT_GET_ENTRIES_SUCCESS';

/*
  --------
  creators
  --------
*/

export const emitClearEntries = () => ({
  type: EMIT_CLEAR_ENTRIES
});

/**
 * @function emitSetEntries
 * @param {Array.<Entry>} entries
 * @returns {any}
 */
export const emitSetEntries = (entries) => (dispatch, getState) => {
  const {
    application: { userId },
    timecard: { dataEntryPeriod }
  } = getState();

  const stagedEntries = [
    ...getStagedForDeletionEntries(dataEntryPeriod, userId),
    ...getStagedForEditEntries(dataEntryPeriod, userId),
    ...getUnsubmittedEntries(dataEntryPeriod, userId)
  ];

  const stagedEntriesIds = stagedEntries.map((stagedEntry) => stagedEntry.EntryId);

  // Filter staged Entries
  const verifiedEntries = !isIterable(entries)
    ? []
    : entries.filter((entry) => !stagedEntriesIds.includes(entry.EntryId));
  // Merge Entries
  const mergedEntries = [...stagedEntries, ...verifiedEntries];
  dispatch({
    type: EMIT_SET_ENTRIES,
    payload: mergedEntries
  });
};

export const emitSetInputFields = (inputFields) => ({
  type: EMIT_SET_INPUT_FIELDS,
  payload: inputFields
});

export const emitInputUpdate = (id, value) => ({
  type: EMIT_INPUT_UPDATE,
  payload: { id, value }
});

export const emitSetWarningCount = (count) => ({
  type: EMIT_SET_WARNING_COUNT,
  payload: count
});

export const emitSetUnsubmittedCount = (count) => ({
  type: EMIT_SET_UNSUBMITTED_COUNT,
  payload: count
});

export const emitSetTotalHours = (hours) => ({
  type: EMIT_SET_TOTAL_HOURS,
  payload: hours
});

export const emitClearInputInvalidations = () => ({
  type: EMIT_CLEAR_INPUT_INVALIDATIONS
});

export const emitInputInvalidationUpdate = (id, value) => ({
  type: EMIT_INPUT_INVALIDATION_UPDATE,
  payload: {
    id,
    value: value || ''
  }
});

export const clearInputFields = () => ({
  type: EMIT_CLEAR_INPUT_FIELDS
});

export const emitGetEntries = (options = {}) => async (dispatch, getState) => {
  const {
    timecard: { dataEntryPeriod },
    application: { online, userId, userCredentials }
  } = getState();

  response = undefined;

  const entryPeriod = options.entryPeriod ?? dataEntryPeriod;
  const { fallbackDataEntryPeriod } = options;

  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: true
  });

  if (online) {
    try {
      response = await login(userId, userCredentials, entryPeriod);
    } catch (error) {
      const errorCode = error.response?.status;
      response = errorCode && {
        errorCode
      };
      if (fallbackDataEntryPeriod) {
        dispatch({
          type: EMIT_SET_DATA_ENTRY_PERIOD,
          payload: fallbackDataEntryPeriod
        });
      }
    }
  } else {
    const timecardData = getTimecard(entryPeriod, userId);
    if (!isEmpty(timecardData)) {
      response = {
        data: {
          TimeCard: timecardData
        }
      };
    } else {
      response = DEFAULT_TIMECARD_RESPONSE;
      response.data.TimeCard.DataEntryPeriod = entryPeriod;
    }
  }

  if (!response || !response.data || response.errorCode || response.errorMessage) {
    const errorMessage = getErrorMessageFromCode(
      response?.errorCode,
      response?.errorMessage ?? 'Failed to retrieve entries, please try again later.'
    );
    emitSetModal(errorMessage)(dispatch);
    // eslint-disable-next-line no-console
    console.error(response);
  } else {
    const rememberUser = equals(getUserId(), userId);
    clearDuplicateEntries(dataEntryPeriod, userId);
    emitSetAllData(response, userId, userCredentials, rememberUser)(dispatch, getState);
  }
  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: false
  });
};

/**
 * @function emitAddEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const emitAddEntry = (entry) => (dispatch, getState) => {
  const {
    application: { userId },
    entries: { entries },
    timecard: { dataEntryPeriod }
  } = getState();
  addUnsubmittedEntry(entry, dataEntryPeriod, userId);
  emitSetEntries(entries)(dispatch, getState);
};

/**
 * @function emitSetEditingEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const emitSetEditingEntry = (entry) => (dispatch) => {
  dispatch({
    type: EMIT_SET_EDITING_ENTRY,
    payload: entry
  });
};

/**
 * @function emitEditEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const emitEditEntry = (entry) => (dispatch, getState) => {
  /**
   * @type {{
   *  entries: { entries: Array.<Entry>, editingEntry: Entry }
   * }}
   */
  const {
    application: { userId },
    entries: { entries, editingEntry },
    timecard: { dataEntryPeriod }
  } = getState();

  const editedEntry = entries.find((item) => item.EntryId === editingEntry.EntryId);

  // If the edited entry has the same values, do not edit
  if (!equals(omit(['StatusMessage'], editedEntry), omit(['StatusMessage'], entry))) {
    const updated = entries.map((item) => {
      if (item.EntryId === editingEntry.EntryId) {
        const updatedItem = entry;
        const entriesStagedForEditing = getStagedForEditEntries(dataEntryPeriod, userId);
        const isEntryStagedForEditing = any((stagedForEditEntry) => stagedForEditEntry.EntryId === updatedItem.EntryId)(
          entriesStagedForEditing
        );
        if (item.Status === ENTRY_STATUS.SAVED || item.Status === ENTRY_STATUS.APPROVED) {
          updatedItem.Status = ENTRY_STATUS.EDITED;
          addStagedForEditEntry(updatedItem, dataEntryPeriod, userId);
        } else if (item.Status === ENTRY_STATUS.EDITED || isEntryStagedForEditing) {
          replaceStagedForEditEntry(updatedItem, dataEntryPeriod, userId);
        } else {
          replaceUnsubmittedEntry(updatedItem, dataEntryPeriod, userId);
        }
        return updatedItem;
      }
      return item;
    });

    dispatch({
      type: EMIT_SET_ENTRIES,
      payload: [...updated]
    });
  }

  dispatch({
    type: EMIT_SET_EDITING_ENTRY,
    payload: {}
  });
};

/**
 * @function emitRemoveEntry
 * @param {Entry} entry
 * @returns {void}
 */
export const emitRemoveEntry = (entry) => (dispatch, getState) => {
  const {
    application: { userId },
    entries: { entries },
    timecard: { dataEntryPeriod }
  } = getState();

  let updatedEntries;

  const entriesStagedForEditing = getStagedForEditEntries(dataEntryPeriod, userId);
  const isEntryStagedForEditing = any((stagedForEditEntry) => stagedForEditEntry.EntryId === entry.EntryId)(
    entriesStagedForEditing
  );

  if (
    entry.Status === ENTRY_STATUS.SAVED ||
    entry.Status === ENTRY_STATUS.APPROVED ||
    entry.Status === ENTRY_STATUS.EDITED ||
    isEntryStagedForEditing
  ) {
    if (entry.Status === ENTRY_STATUS.EDITED || isEntryStagedForEditing) {
      removeStagedForEditEntry(entry.EntryId, dataEntryPeriod, userId);
    }
    const updatedEntry = entry;
    updatedEntry.Status = ENTRY_STATUS.DELETED;
    addStagedForDeletionEntry(updatedEntry, dataEntryPeriod, userId);
    updatedEntries = entries.map((ogEntry) => {
      if (ogEntry.EntryId === updatedEntry.EntryId) {
        return updatedEntry;
      }
      return ogEntry;
    });
  } else {
    removeUnsubmittedEntry(entry.EntryId, dataEntryPeriod, userId);
    updatedEntries = entries.filter((ogEntry) => ogEntry.EntryId !== entry.EntryId);
  }
  dispatch({
    type: EMIT_SET_ENTRIES,
    payload: [...updatedEntries]
  });
};

/**
 * @function emitSubmitEntries
 * @param {Array.<Entry>} timeEntries
 * @param {any} [ignoredWarnings]
 * @returns {any}
 */
export const emitSubmitEntries = (ignoredWarnings) => async (dispatch, getState) => {
  response = undefined;
  /**
   * @type {{
   *  application: { userId: string, userCredentials: string }
   *  timecard: {dataEntryPeriod: any, correlationId: string }
   * }}
   */
  const {
    application: { userId, userCredentials },
    timecard: { dataEntryPeriod, correlationId }
  } = getState();
  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: true
  });

  try {
    response = await submitTimeCard(userId, userCredentials, dataEntryPeriod, correlationId, ignoredWarnings);
  } catch (error) {
    response = {
      errorCode: error?.response?.status ?? 0
    };
  }

  if (!response || response.errorCode || response.errorCode) {
    const errorMessage = getErrorMessageFromCode(
      response?.errorCode,
      'Error submitting your time cards, please try again.'
    );
    emitSetModal(errorMessage)(dispatch);
    // eslint-disable-next-line no-console
    console.error(response);
  } else {
    // Time Card
    const timecard = response?.data?.TimeCard;
    // TimeCard submission error (no response)
    const timeCardError = timecard?.TimeCardError?.Message;
    // TimeCard submission status
    const status = timecard?.TimeCardStaus;
    // LineItems
    const rows = timecard?.LineItems;
    // Time Card OverallStatus
    const overallStatus = timecard?.OverAllTimeCardStatus;

    if (isNil(timecard)) {
      emitSetModal(getErrorMessageFromCode(0))(dispatch);
    } else if (timeCardError) {
      emitSetModal('Your time has been rejected. Make sure your unsubmitted time entries are correct.')(dispatch);
    } else {
      dispatch({
        type: EMIT_SET_TIME_CARD_STATUS,
        payload: status
      });
      dispatch({
        type: EMIT_SET_OVERALL_TIME_CARD_STATUS,
        payload: overallStatus
      });
      if (correlationId === timecard.CorrelationId) {
        rows.map((row) => {
          const entry = row;
          if (entry.Action !== '' && !entry.Editable) {
            entry.Editable = true;
          }
          if (entry.EntryId === '') {
            // Find local entry's generated extDocumentNo to match against SAP data
            const extDocumentNoMatch = getUnsubmittedEntries(dataEntryPeriod, userId).find(
              (localEntry) => localEntry.ExtDocumentNo === entry.ExtDocumentNo
            );
            entry.EntryId = extDocumentNoMatch.EntryId;
          }
          return entry;
        });

        if (status === SUBMISSION_STATUS.SAVED) {
          // Filter Line Items if some have been deleted
          const statusMessageRows = rows.filter((row) => row.StatusMessage);
          const unchangedRows = rows.filter((row) => !row.StatusMessage);
          const savedRows = statusMessageRows.filter(
            (row) =>
              row.StatusMessage[0]?.MessageType === ENTRY_STATUS_MESSAGE_TYPE.SAVED &&
              row.StatusMessage[0]?.Message !== ENTRY_STATUS.DELETED
          );
          const newEntries = [...unchangedRows, ...savedRows];

          dispatch({
            type: EMIT_SET_ENTRIES,
            payload: newEntries
          });

          // Set Local Storage
          if (equals(getUserId(), userId)) {
            setTimecard(timecard, dataEntryPeriod, userId);
          }

          // Clear staged entries
          clearUnsubmittedEntries(dataEntryPeriod, userId);
          clearStagedForEditEntries(dataEntryPeriod, userId);
          clearStagedForDeletionEntries(dataEntryPeriod, userId);
        } else if (status === SUBMISSION_STATUS.NOPERNR || status === SUBMISSION_STATUS.INELIGIBLE) {
          emitSetModal(getTimeCardErrors(status, overallStatus?.Message))(dispatch);
        } else {
          const errorMessage =
            status === SUBMISSION_STATUS.WARNINGS
              ? 'You have warnings in your entry, please correct and retry.'
              : 'Your time has been rejected. Make sure your unsubmitted time entries are correct.';
          // replace local storage entries with new ones
          replaceStagedEntries(rows, dataEntryPeriod, userId);
          // set all entries to new ones
          emitSetEntries(rows)(dispatch, getState);
          emitSetModal(errorMessage)(dispatch);
        }
      } else {
        const errorMessage = 'TimeCard Correlation Id does not match.';
        emitSetModal(errorMessage)(dispatch);
      }
    }
  }
  dispatch({
    type: EMIT_SET_DATA_IN_FLIGHT,
    payload: false
  });
};

/*
  -------
  reducer
  -------
*/

/**
 * @type {{
 *  entries: Array.<Entries>,
 *  timecardMessage: React.ReactNode,
 *  inputField: InputFields,
 *  inputFieldInvalidations: InputFieldsInvalidations
 * }}
 */
export const INITIAL_STATE = {
  entries: [],
  warningCount: 0,
  unsubmittedCount: 0,
  totalHours: 0,
  editingEntry: {},
  inputFields: {
    date: '',
    woNumber: '',
    aaType: '',
    wageType: '',
    hours: '',
    units: '',
    miles: '',
    startTime: '',
    endTime: '',
    shortText: '',
    valBasis: '',
    ocCode: '',
    previousDay: false
  },
  inputFieldInvalidations: {
    date: '',
    woNumber: '',
    aaType: '',
    wageType: '',
    units: '',
    startTime: '',
    endTime: '',
    shortText: '',
    valBasis: '',
    ocCode: ''
  }
};

export default handleActions(
  {
    [EMIT_SET_ENTRIES]: (state, action) => ({
      ...state,
      entries: action.payload
    }),
    [EMIT_SET_EDITING_ENTRY]: (state, action) => ({
      ...state,
      editingEntry: action.payload
    }),
    [EMIT_CLEAR_ENTRIES]: (state) => ({
      ...state,
      entries: []
    }),
    [EMIT_SET_INPUT_FIELDS]: (state, action) => ({
      ...state,
      inputFields: {
        ...INITIAL_STATE.inputFields,
        ...action.payload
      }
    }),
    [EMIT_CLEAR_INPUT_FIELDS]: (state) => ({
      ...state,
      inputFields: INITIAL_STATE.inputFields
    }),
    [EMIT_INPUT_UPDATE]: (state, action) => ({
      ...state,
      inputFields: {
        ...state.inputFields,
        [action.payload.id]: action.payload.value
      }
    }),
    [EMIT_CLEAR_INPUT_INVALIDATIONS]: (state) => ({
      ...state,
      inputFieldInvalidations: {
        ...INITIAL_STATE.inputFieldInvalidations
      }
    }),
    [EMIT_INPUT_INVALIDATION_UPDATE]: (state, action) => ({
      ...state,
      inputFieldInvalidations: {
        ...state.inputFieldInvalidations,
        [action.payload.id]: action.payload.value
      }
    }),
    [EMIT_SET_UNSUBMITTED_COUNT]: (state, action) => ({
      ...state,
      unsubmittedCount: action.payload
    }),
    [EMIT_SET_TOTAL_HOURS]: (state, action) => ({
      ...state,
      totalHours: action.payload
    }),
    [EMIT_SET_WARNING_COUNT]: (state, action) => ({
      ...state,
      warningCount: action.payload
    })
  },
  INITIAL_STATE
);
